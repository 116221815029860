@import "./Fluid/src/vendors/vendors";
@import "./Fluid/src/tools/tools";
@import "./Fluid/src/settings/settings";

:root {
  --antd-wave-shadow-color: #{$primary};
}

.ant-layout-sider {
  min-width: initial !important;
  max-width: initial !important;
}

.ant-layout-header {
  line-height: inherit !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit !important;
}

.ant-picker-input > input,
.ant-picker-suffix {
  color: inherit !important;
  text-align: inherit;
}

.ant-select-arrow,
.ant-picker-suffix {
  transform: translateY(-2px) !important;
  line-height: 0 !important;
  font-size: 1.5em !important;
}

.ant-select-arrow {
  > .icon {
    transform: translate(-30%, -30%) !important;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: unset !important;
  border-width: 0.1px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: $lineheight-default !important;
}

.ant-select-selector {
  padding: 0 !important;
}

.has-icon-left .ant-select-selection-search,
.has-icon-left .ant-select-selector {
  padding-left: 2em !important;
}

.control.has-icon-left {
  .ant-select.input {
    padding-left: 0 !important;
  }
}

.ant-select-selection-search {
  left: 0 !important;
}

.ant-tabs-nav {
  @include media-breakpoint-below(md) {
    padding: 0 5% 0 5%;
  }

  .ant-tabs-tab {
    padding-left: 1em;
    padding-right: 1em;
  }
  .ant-tabs-tab:hover,
  .ant-tabs-tab-active {
    color: $primary !important;
  }
}
.ant-slider-track,
.ant-tabs-ink-bar {
  background-color: $primary !important;
}

.ant-carousel {
  cursor: move;
}

.ant-slider-handle {
  border-color: $primary !important;
}

.ant-tabs-tabpane,
.ant-tabs-tab {
  outline: 0 !important;
}

.ant-tabs-bar {
  padding: 0 4% !important;
  margin-bottom: 0;
  @include media-breakpoint-above(sm) {
    padding: 0 10% !important;
  }
}

.ant-tabs-content {
  height: inherit;
}

.ant-tooltip-inner {
  text-align: center !important;
  padding: 1rem !important;
  background-color: $dark !important;
}

.ant-progress {
  .ant-progress-bg {
    background-color: $primary !important;
    height: 0.5em !important;
  }

  &.square {
    .ant-progress-inner,
    .ant-progress-bg {
      border-radius: 0 !important;
    }
  }

  &.is-lg {
    .ant-progress-bg {
      height: 1em !important;
    }
  }
}

.ant-empty-image {
  line-height: 1 !important;
  height: auto !important;
}

.ant-pagination {
  .ant-pagination-item-link {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: $body-color !important;
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a,
  .ant-pagination-item-link:focus,
  .ant-pagination-item-link:hover {
    color: $body-color !important;
    border-color: $primary !important;
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-item-link:hover,
  .ant-pagination-item-active,
  .ant-pagination-item-active:focus a {
    color: $primary !important;
    border-color: $primary !important;
  }
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $primary !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: $primary !important;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  line-height: 1 !important;
}
.ant-steps-icon > .anticon {
  transform: translateY(-4px) !important;
}

.ant-picker:not(.shadow-none) {
  @include hover(".ant-picker-focused") {
    border-color: rgba(218, 55, 67, 0.5) !important;
    box-shadow: inset 0 1px 2px rgba(15, 15, 15, 0.1), 0 0 0 0.2rem rgba(218, 55, 67, 0.5) !important;
  }
}

.ant-select-selection-placeholder,
.ant-select-selector {
  height: auto !important;
  border: 0 !important;
  line-height: $lineheight-default !important;
}

.ant-picker-dropdown.center {
  .ant-picker-panel-container {
    position: relative;
    overflow: visible;
  }
  .ant-picker-panel {
    position: absolute;
    left: 105px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    background: $white;
    transform: translateX(-50%);
  }
}

.ant-carousel .slick-dots {
  z-index: 0!important;

  width: auto;
}
.ant-carousel .slick-dots li button{
color: transparent;
}
.slick-dots li.slick-active button:before{
  color: transparent!important;
  }
  .slick-dots li button:before{
    color: transparent!important;
  }
