//
// Layout Settings
//
// Using px unit to keep spacing constant regardless of font-size

//
// Setting
//
// Header
$header-background:                  if($design-theme == light, $white, $dark) !default;
$header-color:                       clr-auto($header-background) !default;
$header-bordertop:                   none !default;
$header-height:                      96px !default;
$header-padding-x:                   24px !default;
$header-zindex:                      15 !default;
$header-blur:                        3px !default;
$header-blur-transparency:           .9 !default;
$header-transition:                  min-height .35s ease-in-out, line-height .35s ease-in-out !default;

// main and body layout
$body-background:                    if($design-theme == light, $white, hsl(0, 0%, 15%)) !default;
$body-color:                         clr-auto($body-background, $white, $gray-700) !default;
$main-background:                    $body-background !default;

// Footer
$footer-background:                  null !default;
$footer-color:                       null !default;
$footer-padding-x:                   24px !default;
$footer-padding-y:                   48px !default;
$footer-zindex:                      0 !default;

// Sidebar
$sidebar-background:                 if($design-theme == light, $white, $gray-700) !default;
$sidebar-color:                      clr-auto($sidebar-background, $white, $dark) !default;
$sidebar-zindex:                     20 !default;
$sidebar-transition:                 left .5s ease-in-out !default;
$sidebar-minwidth:                   250px !default;
$sidebar-maxwidth:                   60vw !default;

// Overlay
$overlay-background:                 rgba($black, .75) !default;
$overlay-zindex:                     100 !default;
$overlay-transition:                 opacity .35s ease-in-out, visibility .35s ease-in-out !default;

//
// Derived
//
// Header
$header-height-collapsed:            80px !default;
