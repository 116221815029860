 .services .glider-track>.slide:nth-child(2n) {
    background-image: linear-gradient(#ebcad9,#ebcad9);
}
 .services .glider-track>.slide {
    background-image: linear-gradient(#c3d8f6,#c3d8f6);
}
 .services .slide .img {
    margin-top: -55%;
}
.services .slide {
    margin: 5% .5% 0;
}

.services .slide {
    padding: 1rem;
}
.img-thnk-u{
    width:15% ;
    margin-top:8%
}

@media (max-width:600px){
    .img-thnk-u{
        width:50% ;
        margin-top:30%
    }
    .s-thank-txt{
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}