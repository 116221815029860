.whatsapp-bot{
    position: fixed;
    bottom: 20px;
    left: 2%;
    z-index: 99999999;
}
.free-const{
    position: fixed;
    bottom: 20px;
    right: 2%;
    z-index: 99999999;
}
.whatsapp-btn{
  width: 60px;
    background: 	#25D366;
    color: white;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width:600px)
{
   
    .whatsapp-bot{
        right: 25%;
        left: 5%;
    }
}