.clr-green{
    color: #195334;
}
.ep1-flex{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ep1-flex-left{
    width: 55%;
}
.ep1-flex-right{
    width: 44%;
}
.ep1-flex-right img{
    width: 100%;
}
.ep1-flex-left h6{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 0px;
}
.ep1-flex-left h1{
    font-size: 5.5rem;
    font-weight: 900;
    margin-bottom: 0px;
    letter-spacing: 18px;
}
.main-everpure{
    background: #ffeaea;
    color: #195334;
}
.ep1-flex-left h4{
    margin-bottom: 10px;
    font-size: 2rem;
    font-weight: 300;
}
.ep1-flex-left h3{
   
    margin-bottom: 5px;
 
}
.ep1-flex-left p{
  
    font-size: 1.2rem;
   line-height: 1.3;
   margin-bottom: 0;
}
.everpure-grid-sec{
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
}
.everpure-grid-sec img{
    width: 100%;
}
.grid2{
    background: #bfe085;
}
.grid4{
    background: #8bd38f;
}
.grid5{
    background: #a8a97e;
}
.grid7{
background: #d8eed2;
}
.grid10{
    background: #b0ccd2;
}
.grid12{
    background: #ffa293;
}
.everpure-problem-main{
    display: flex;
    align-items: center;
    background: #ffeaea;
}
.everpure-problem-main img{
    width:36%;
}
.everpure-problem-right{
padding: 0 5%;
color: #195334;

}
.everpure-problem-right h5{
font-weight: 300;
margin-bottom: 0;
}
.everpure-problem-right h3{
    font-weight: 700;
margin: 1% 0;
font-size: 3rem;
    }
    .everpure-problem-right p{
        line-height: 1.4;
        letter-spacing: 2px;
        font-size: 20px;
        margin-bottom: 0;
    }
    .everpure-reverse-flex{
        flex-direction: row-reverse;
    }
    .img-minus-margin-everpure{
        width: 100%;
    margin-bottom: -12%;
    position: relative;
    }
    .everp-approach{
        padding: 5%;
        text-align: center;
    }
    .appoach-img-sec-main {
        background: #ffeaea;
        padding: 6% 0;
        text-align: center;
    }
    .appoach-img-sec-main img{
        width: 100%;
    }
    .everp-performance{
        text-align: center;
        color: #195334;
        padding: 5%;
    }
    .everp-performance h3{
font-size: 3rem;
    }
    .everp-performance-grid{
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(280px, 1fr) );
        gap: 10px;
        row-gap: 20%;
        margin-top: 5%;
    }
    .performance-grid-child h4{
        text-align: center;
        font-size: 2.6rem;
        font-weight: 700;
        margin-bottom: 6px;
    }
    .flex-everpure-gplus{
        display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 5%;
        text-align: center;
    }
    .flex-everpure-gplus img{
        width: 40%;
        margin-bottom: 20px;
    }
    .flex-everpure-gplus h3{
        font-size: 1.6rem;
    }

    @media (max-width:600px){

        .ep1-flex{
flex-direction: column;
        }
        .ep1-flex-left {
            width: 100%;
        }
        .ep1-flex-right {
            width: 100%;
        }
        .everpure-problem-right h3{
            font-size: 1.4rem;
        }
        .everpure-problem-right p {
            line-height: 1.2;
         
            font-size: 13px;
          
        }
        .everpure-problem-right{
            padding: 5%;
        }
        .everp-performance-grid {
        
            row-gap: 2%;
          grid-template-columns: 1fr 1fr;
        }
        .flex-everpure-gplus-left h3{
            font-size: 1rem;
        }
        .flex-everpure-gplus-right h3{
            font-size: 1rem; 
        }
        .flex-everpure-gplus img{
width: 34px;
        }
        .flex-everpure-gplus{
gap: 10px;
        }
    }