@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 900;
	src: local('Poppins Black'), url('./Poppins-Black.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 900;
	src: local('Poppins BlackItalic'), url('./Poppins-BlackItalic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local('Poppins Bold'), url('./Poppins-Bold.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 700;
	src: local('Poppins BoldItalic'), url('./Poppins-BoldItalic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	src: local('Poppins ExtraBold'), url('./Poppins-ExtraBold.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 800;
	src: local('Poppins ExtraBoldItalic'), url('./Poppins-ExtraBoldItalic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 200;
	src: local('Poppins ExtraLight'), url('./Poppins-ExtraLight.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 200;
	src: local('Poppins ExtraLightItalic'), url('./Poppins-ExtraLightItalic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 400;
	src: local('Poppins Italic'), url('./Poppins-Italic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local('Poppins Light'), url('./Poppins-Light.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 300;
	src: local('Poppins LightItalic'), url('./Poppins-LightItalic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local('Poppins Medium'), url('./Poppins-Medium.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 500;
	src: local('Poppins MediumItalic'), url('./Poppins-MediumItalic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local('Poppins Regular'), url('./Poppins-Regular.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local('Poppins SemiBold'), url('./Poppins-SemiBold.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 600;
	src: local('Poppins SemiBoldItalic'), url('./Poppins-SemiBoldItalic.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 100;
	src: local('Poppins Thin'), url('./Poppins-Thin.ttf');
}

@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 100;
	src: local('Poppins ThinItalic'), url('./Poppins-ThinItalic.ttf');
}

.Poppins {
	font-family: 'Poppins' !important;
}
