.main-vogue{
    background: brown;
}

.vogue-flex{
    display: flex;
    align-items: center;
    justify-content: center;
}
.vogue-flex-left{
    width: 60%;
}
.vogue-flex-right{
    width: 40%;
}
.vogue-flex img{
    width: 100%;
}

.ep1-flex-left h6{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 0px;
}
.vogue-flex-left h1{
    font-size: 4rem;
    font-weight: 900;
    margin-bottom: 0px;
    letter-spacing: 10px;
}
.vogue-flex2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.vogue-flex2-left{
    width: 60%;
}
.vogue-flex2-right{
    width: 40%;
}
.vogue-flex2 img{
    width: 100%;
}
.vogue-flex2-left-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
    row-gap: 20%;
}
.vogue-flex2-left-grid-item{
    border-left: 2px solid white;
    padding-left: 20px;
}
.vogue-flex2-left-grid-item p{
    margin: 0;
}
.vogue-flex2-left-grid-item h3{
    font-size: 2.2rem;
    margin-bottom: 10px;
}
.vogue-problem h5{
    font-size: 1.2rem;
    font-weight: 300;
}
.vogue-problem{
    text-align: center;
    padding: 5% 0;
    color: black;
}
.vogue-problem h3{
    font-size: 2.3rem;
    font-weight: 700;

}
.vogue-problem p{
  max-width: 50%;
  margin: auto;

}
.vogue-flex-third{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b9a089;
    
}
.vogue-flex-third-left{
    width: 40%;
}
.vogue-flex-third-left img{
    width: 70%;
}
.vogue-flex-third-right{
    width: 60%;
    color: white;
}
.vogue-problem-right{
    color: white;
}
.appoach-img-sec-vogue{
    background: #b9a089;
    text-align: center;
    padding: 5%;
}
.vogue-problem-right img{
    width: 100%;
}

.main-lepr{
    background-color: white;
   
}
.cus-main-lepr{
    color: #69bd45;
}

.lepr-f-l {
    width: 50%;
}
.lepr-f-r {
    width: 50%;
}
.lepr-flex-third{
    background: #69bd45;
}
.lepr-flex-everpure-gplus img{
    min-height: 100px;
    width: 90px;
}
@media (max-width:600px){

    .vogue-flex {
        flex-direction: column;
    }
    .vogue-flex-left{
        width: 100%;
    }
    .vogue-flex-right{
        width: 100%;
    }
    .vogue-flex2 {
        flex-direction: column;
        margin-top: 10px;
    }
    .vogue-flex2-left{
        width: 100%;
    }
    .vogue-flex2-right{
        width: 100%;
    }
    .vogue-problem p {
        max-width: 70%;
    
    }
    .vogue-flex-third{
        flex-direction: column;
    }
    .vogue-flex-third-left{
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    .vogue-flex-third-right{
        width: 100%;
    }
    .vogue-problem h3 {
        font-size: 2rem;
      
    }
    .lepr-flex-everpure-gplus img {
        min-height: 69px;
        width: 64px;
    }
}